import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import '../assets/style.css';
import { Row, Col, Container, Card, Button, Figure} from 'react-bootstrap';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulWorkSuccessStories:{
        nodes:cstudies
    }} = data

    return (
        <div className="case-studies">  
            <Container> 
            <Row>
                <div className="our-cs">
                    <h2 className="title">Success stories</h2> 
                    <span className="subtitle">Our work is visible to millions of daily digital users through our clients platforms.</span>
                </div>
                {cstudies.slice(0,3).map((cs)=>{
                return <>
                <Col xs={12} md={4} lg={4} className="cs-column">
                    <a href={`/work/${cs.csSlug}/`}> 
                    <Card className={cs.csSlug}  key={cs.id} >
                        <Card.Body >
                            <div className="both-images">
                                <img className="cs-img" src={cs.csImage.fluid.src} alt="Case img" style={{maxWidth:"100%", maxHeight:"247px", objectFit:"cover"}} />
                                <div className="cs-top-img" style={{display:"none"}}> 
                                    <div className="top-img" > 
                                        {/* <Image  fluid={cs.csTopImage.fluid} alt="Case img"></Image> */}
                                    </div>
                                </div>
                            </div>
                            
                            <Card.Text className="cs-title"><h3>{cs.csTitle} </h3></Card.Text>
                            <Button href={`/work/${cs.csSlug}/`} className="tertiary-btn-s">{cs.csBtn}<Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png')}  />
                                    </Figure></Button>
                        </Card.Body>
                    </Card>
                    </a>
                </Col>
                </>
                })}
            </Row> 
            </Container></div>
    )
}

export const query = graphql`
{
    allContentfulWorkSuccessStories( filter: {node_locale: {eq: "en-US"}}, sort: {fields: createdAt, order: DESC}) {
        nodes {
        
        id
        csSlug
        csTitle
        csTopImage {
            fluid(quality: 100) {
            ...GatsbyContentfulFluid
            }
        }
        csImage {
            fluid( quality:100) {
            src
            }
        }
        csBtn
        }
    }
}
`


